/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';
import { useState } from 'preact/hooks';

import { Z_INDEX_MAX } from '../constants';

type DialogProps = {
    children : VNode,
};

export const Dialog = ({
    children
} : DialogProps) : VNode => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ suppressDialog, setSuppressDialog ] = useState(false);

    if (
        suppressDialog ||
        typeof HTMLDialogElement !== 'function' ||
        !('showModal' in HTMLDialogElement.prototype)
    ) {
        return children;
    }

    return (
        <dialog
            style={
                {
                    zIndex: Z_INDEX_MAX
                }
            }
            ref={
                el => {
                    if (isOpen) {
                        return;
                    }

                    if (typeof el?.showModal === 'function') {
                        try {
                            el.showModal();
                        } catch {
                            setSuppressDialog(true);
                        }

                        setIsOpen(true);
                    }
                }
            }
        >
            { children }
        </dialog>
    );
};
